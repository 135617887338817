import styled from "styled-components";
import { PageWrapper } from "../atoms";
import { Cubes, Chapter } from "../molecules";
import { BeigeButton } from "../molecules/buttons";
import { News } from "../../organisms";

import company from "../../../assets/images/pr/PR_anymedi 1.png";
import company2 from "../../../assets/images/pr/PR_anymedi 2.png";
import company3 from "../../../assets/images/pr/PR_anymedi 3.png";
import { useEffect, useCallback } from "react";

const MobilePRComponent = () => {
  const renderMap = useCallback(() => {
    // 다음 지도 생성
    new window.daum.roughmap.Lander({
      timestamp: "1650613353442",
      key: "29xfx",
      "mapHeight" : "200",
    }).render();
  }, []);

  useEffect(() => {
    renderMap();
  }, [renderMap]);

  return (
    <PR>
      <GreyBackgroundBoxTop />
      <WhiteBackgroundBoxTop />
      <GreyBackground />
      <PageWrapper>
        <PRWrapper>
          <BackgroundText>PR</BackgroundText>
          <PageTitle>ABOUT US</PageTitle>
          <Cubes />
          <PageSubTitle>맞춤형 수술 솔루션의 미래를 선도합니다.</PageSubTitle>
          <Company>
            <Chapter name="COMPANY" />
            <CompanyName>애니메디솔루션(주)</CompanyName>
            <CompanyWrapper>
              <CompanyLeft>
                <CompanyImage src={company} />
                <CompanyImage2 src={company2} />
                <CompanyImage3 src={company3} />
                <CompanyImageDesc>
                  * 이노핏은 애니메디솔루션의 미용 부문 솔루션입니다.
                </CompanyImageDesc>
              </CompanyLeft>
              <CompanyRight>
                <CompanyContent>
                  Surgical Solutions for K-Medical & K-Beauty
                </CompanyContent>
                <CompanySubContent>
                  애니메디솔루션은 의료영상 정보를 기반으로 환자 맞춤형 <br />
                  수술 솔루션을 개발하고 제작하는 기업입니다.
                </CompanySubContent>
                <a href="https://anymedi.com" target="_blank" rel="noreferrer" className="btn-anymedisite">
                  <BeigeButton
                    name="홈페이지 바로가기"
                    arrow
                  />
                </a>
              </CompanyRight>
            </CompanyWrapper>
          </Company>
          <NewsSection id="news">
            <Chapter name="NEWS" />
            <SectionTitle>이노핏 소식</SectionTitle>
            <News />
          </NewsSection>
          <Map id="map">
            <Chapter name="MAP" />
            <SectionTitle>찾아오는 길</SectionTitle>
            <MapWrapper>
              <div
                id={window.innerWidth > 768 ? "daumRoughmapContainer1656317866407" : "daumRoughmapContainer1656321910021"}
                className="root_daum_roughmap root_daum_roughmap_landing"
                style={{ width: "100%" }}
              />
              {/* <MapInfoWrapper>
                <MapInfo>
                  <MapInfoDT>지하철 이용</MapInfoDT>
                  <MapInfoDD>잠실역 (2호선, 7호선)_7번출구 3분거리</MapInfoDD>
                </MapInfo>
                <MapInfo>
                  <MapInfoDT>버스 이용</MapInfoDT>
                  <MapInfoDD>
                    잠실역 버스 정류장_342, 2415, 3216, 3318, 3319, <br />
                    3412, 4318, 16, 30-3, 30-5
                  </MapInfoDD>
                </MapInfo>
              </MapInfoWrapper> */}
            </MapWrapper>
          </Map>
        </PRWrapper>
      </PageWrapper>
      <GreyBackgroundBottomBox />
      <WhiteBackgroundBottomBox />
      <GreyBackgroundBottom />
    </PR>
  );
};

const BackgroundText = styled.p`
  position: absolute;
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 150px;
  color: #f3f3f3;
  z-index: -1;
  right: 240px;
  top: -30px;
  @media screen and (max-width: 800px) {
    font-size: 100px;
    right: 60px;
    top: 42px;
  }
  @media screen and (max-width: 420px) {
    font-size: 70px;
    line-height: 72px;
    top: 76px
  }
`;

const WhiteBackgroundBoxTop = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  width: 180px;
  height: 180px;
  background: #fff;
  border-radius: 0px 0px 180px 0;
  z-index: -1;
  @media screen and (max-width: 800px) {
    top: 50px;
    border-radius: 0px 0px 100px 0;
  }
  @media screen and (max-width: 420px) {
    top: 34px;
    border-radius: 0px 0px 80px 0;
  }
`;
const GreyBackgroundBoxTop = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  z-index: -1;
  @media screen and (max-width: 800px) {
    top: 50px;
  }
  @media screen and (max-width: 420px) {
    top: 34px;
  }
`;
const GreyBackground = styled.div`
  position: absolute;
  right: 0;
  top: 240px;
  width: 70vw;
  height: 1563px;
  background: #f3f3f3;
  border-radius: 180px 0px 0px 180px;
  z-index: -1;
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    border-radius: 100px 0px 0px 100px;
    top: 230px;
    height: 1730px;
  }
  @media screen and (max-width: 420px) {
    top: 214px;
    border-radius: 80px 0px 0px 80px;
    height: 1376px;
  }
`;
const PR = styled.div`
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  @media screen and (max-width: 800px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media screen and (max-width: 420px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
const PRWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;
const PageTitle = styled.p`
  font-family: "Saira Condensed";
  font-weight: bold;
  font-size: 52px;
  line-height: 42px;
  color: #383838;
  margin-bottom: 70px;
  @media screen and (max-width: 800px) {
    font-size: 48px;
    line-height: 42px;
  }
  @media screen and (max-width: 420px) {
    font-size: 44px;
    margin-bottom: 50px;
  }
`;
const PageSubTitle = styled.p`
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-top: 16px;
  @media screen and (max-width: 420px) {
    margin-top: 14px;
    font-size: 16px;
    line-height: 26px;
  }
`;

const Company = styled.div`
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 80px;
  }
`;
const CompanyWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const CompanyLeft = styled.div`
  margin-right: 60px;
  @media screen and (max-width: 1100px) {
    margin-right: 40px;
  }
  @media screen and (max-width: 800px) {
    margin-right: 0;
  }
`;

const CompanyImage = styled.img`
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
const CompanyImage2 = styled.img`
  display: none;
  @media screen and (max-width: 1100px) {
    display: inline-block;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const CompanyImage3 = styled.img`
  display: none;
  width: 100%;
  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`;

const CompanyImageDesc = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 20px;
    margin-top: 16px;
  }
`;
const CompanyRight = styled.div`
  max-width: 540px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
    max-width: unset;
  }
  @media screen and (max-width: 420px) {
    margin-top: 40px;
  }
`;
const CompanyName = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  margin-bottom: 30px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 14px;
    font-size: 22px;
    line-height: 28px;
  }
`;
const CompanyContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #5a5a5a;
  margin-bottom: 20px;
  @media screen and (max-width: 420px) {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 16px;
  }
`;
const CompanySubContent = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05em;
  color: #989898;
  margin-bottom: 60px;

  @media screen and (max-width: 970px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 50px;

    br {
      display: unset;
    }
  }
`;

const NewsSection = styled.div`
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 420px) {
    margin-top: 80px;
  }
`;
const SectionTitle = styled.p`
  font-weight: 500;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.05em;
  color: #383838;
  margin-top: 10px;
  @media screen and (max-width: 420px) {
    font-size: 22px;
    line-height: 28px;
    margin-top: 14px;
  }
`;

const Map = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 420px) {
    margin-top: 130px;
  }
`;
const MapWrapper = styled.div`
  margin-left: auto;
  margin-top: 100px;
  width: 100%;
  max-width: 952px;
  @media screen and (max-width: 420px) {
    margin-top: 80px;
  }
`;

// const MapInfoWrapper = styled.div`
//   margin-top: 20px;
//   @media screen and (max-width: 420px) {
//     margin-top: 30px;
//   }
// `;
// const MapInfo = styled.dl`
//   display: flex;
//   margin-top: 6px;

//   @media screen and (max-width: 420px) {
//     flex-wrap: wrap;
//     margin-top: 10px;

//     > * {
//       width: 100%;
//     }
//   }
// `;
// const MapInfoDT = styled.dt`
//   font-weight: 600;
//   font-size: 15px;
//   line-height: 22px;
//   letter-spacing: -0.05em;
//   color: #5a5a5a;
//   @media screen and (max-width: 800px) {
//     font-size: 14px;
//   }
//   @media screen and (max-width: 420px) {
//     font-size: 16px;
//     line-height: 26px;
//     font-weight: 500;
//   }
// `;
// const MapInfoDD = styled.dd`
//   font-size: 15px;
//   line-height: 22px;
//   letter-spacing: -0.05em;
//   color: #5a5a5a;
//   margin-left: 14px;
//   flex: 1;
//   @media screen and (max-width: 800px) {
//     font-size: 14px;
//   }
//   @media screen and (max-width: 420px) {
//     line-height: 24px;
//     margin-left: 0px;
//   }
// `;

const GreyBackgroundBottomBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 793px;
  width: 180px;
  height: 180px;
  background: #f3f3f3;
  z-index: -1;
  @media screen and (max-width: 800px) {
    bottom: 766px;
  }
  @media screen and (max-width: 420px) {
    bottom: 516px;
    z-index: -2;
  }
`;
const WhiteBackgroundBottomBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 793px;
  width: 180px;
  height: 180px;
  background: #fff;
  z-index: -1;
  border-radius: 0px 0px 0 180px;
  @media screen and (max-width: 800px) {
    border-radius: 0px 0px 0 100px;
    bottom: 766px;
  }
  @media screen and (max-width: 420px) {
    border-radius: 0px 0px 0 80px;
    bottom: 516px;
    z-index: -2;
  }
`;
const GreyBackgroundBottom = styled.div`
  background: #f3f3f3;
  border-radius: 0px 180px 0px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 793px;
  width: 900px;
  z-index: -1;
  @media screen and (max-width: 1100px) {
    width: 55vw;
  }
  @media screen and (max-width: 800px) {
    width: calc(100vw - 20px);
    height: 766px;
    border-radius: 0px 100px 0px 0px;
  }
  @media screen and (max-width: 420px) {
    height: 516px;
    border-radius: 0px 80px 0px 0px;
  }
`;

export default MobilePRComponent;
